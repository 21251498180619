.schedule-meet-component {
  font-family: Inter;
  background: white;
  padding: 24px;
  border-radius: 16px;
  .border-bottom {
    border-bottom: 1px solid #1003240d !important;
    padding-bottom: 24px;
  }
  .title h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: #100324;
    margin: 0;
    padding-left: 10px;
  }
  .create-schedule-btn {
    border: 1px solid #100324cc;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    padding: 10px 15px;
    background-color: #fff;
  }
  .sub-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    color: #100324cc;
  }
  .schedule-meet {
    .create-schedule-btn-box {
      background: linear-gradient(
        270deg,
        rgba(0, 110, 255, 0.15) 0%,
        rgba(33, 112, 216, 0.15) 100%
      );
      padding: 32px;
      border-radius: 12px;
      border: 1px dashed #006eff;
      text-align: center;
      cursor: pointer;
      p {
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
        background: linear-gradient(270deg, #006eff 0%, #2170d8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .schedule-modal {
      background: red !important;
      label {
        padding: 16px 32px;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer !important;
        background-color: white;
        color: black;
        border: 2px solid #008cba;
        border-radius: 15px;
      }
    }
  }

  .schedule-event {
    .schedule-card {
      border: 1px solid #10032426;
      border-radius: 12px;
      padding: 24px;
      background: #f8f7f8;
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 21.78px;
        color: #100324cc;
        text-transform: capitalize;
      }
      .meet-link {
        color: #100324b2;
      }
      .link-area {
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          color: #100324b2;
          line-break: anywhere;
        }
      }
      .link-area {
        border: 1px solid #10032426;
        border-radius: 12px;
        cursor: pointer;
      }
      .date-time {
        border-bottom: 1px solid #1003240d;
        padding-bottom: 17px;
      }
      .user {
        .name-text {
          font-size: 16px;
          font-weight: 600;
          line-height: 19.36px;
          color: #100324cc;
        }
        .role-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          color: #10032480;
        }
      }
      .participants {
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
        }
      }
      .btn-area {
        .btn-primary {
          padding: 12px 0px !important;
          border-radius: 8px !important;
          font-size: 18px;
          font-weight: 700;
          line-height: 17px;
          background: linear-gradient(270deg, #006eff 0%, #2170d8 100%) !important;
        }
        .reschedule {
          border: 1px solid #10032433 !important;
          font-size: 18px;
          font-weight: 500;
          line-height: 17px;
          color: #100324b2;
          border: none;
          padding: 13px 0px !important;
          background: #fff !important;
        }
      }
    }
    .active-event {
      background: linear-gradient(
        270deg,
        rgba(0, 110, 255, 0.1) 0%,
        rgba(33, 112, 216, 0.1) 100%
      ) !important;
      border: none !important;
      .title {
        background: linear-gradient(270deg, #006eff 0%, #2170d8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .date-time {
        color: #10032499;
      }
      .user {
        .name-text {
          color: #10032480;
        }
        .role-text {
          color: #10032480;
        }
      }
    }
  }
}
