@import './homepage/homeLayout.module.scss';
@import './footer/footer.module.scss';
@import './header/header.module.scss';
@import './meet/schedule.module.scss';
@import './handraise/handraise.module.scss';
body {
  font-family: 'Space Grotesk';
  background-color: #ffffff;
}
// #__next {
//   background-color: #ffffff;
// }
.swal-footer {
  text-align: center;
}
.\lk-prejoin {
  height: auto !important;
}

@media screen and (max-width: 760px) {
  .lk-chat {
    height: 80vh !important;
  }
  .lk-grid-layout-wrapper,
  .lk-focus-layout-wrapper {
    height: 82vh !important;
  }
  .\lk-control-bar {
    .\lk-button,
    .\lk-disconnect-button {
      border-radius: 50% !important;
      height: 50px !important;
      width: 50px !important;
      padding: 0 !important;
      // svg {
      //   width: 100%;
      //   height: auto;
      // }
    }
    .\lk-button-group-menu {
      display: none !important;
    }
    .participant-button,
    .hand-raised {
      span {
        display: none;
      }
    }
  }
}
.\lk-participant-placeholder {
  img {
    border-radius: 50%;
  }
}
.lk-focus-toggle-button {
  background: unset !important;
  &:hover {
    background: rgb(44, 40, 40) !important;
  }
}
.lk-focus-custom-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  background-color: rgb(71, 70, 70);
  transition: opacity 0.2s ease-in-out;
  border-radius: 30px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lk-focus-custom-buttons:hover {
  opacity: 1 !important;
  transition: opacity 0.2s ease-in-out;
}
.lk-focus-custom-buttons button {
  color: white !important;
  cursor: pointer;
  &:hover {
    background: rgb(44, 40, 40) !important;
  }
}
.lk-participant-tile:hover .lk-focus-custom-buttons {
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}
.lk-focus-remove-button:hover {
  opacity: 1;
}
.lk-chat {
  align-items: unset !important;
  height: 88vh;
  margin-top: 8px;
  border-radius: 10px;
  .participant-title {
    text-transform: capitalize;
  }
}
.lk-grid-layout-wrapper,
.lk-focus-layout-wrapper {
  // display: flex !important;
  height: 90vh;
  // width: 100vw !important;
}
// .lk-grid-layout, .lk-focus-layout {
//   padding-bottom: 0px !important;
// }
.lk-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.\lk-control-bar {
  z-index: 1400;
  position: absolute;
  top: 95%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  border-top: unset;
  // background: #000000;
}
.lk-button[aria-pressed='true'] {
  background-color: #757474;
}
.lk-button[aria-pressed='true']:hover {
  background-color: #5c5b5b;
}

@media screen and (max-width: 600px) {
  .pre-join-track-button {
    .lk-button {
      border-radius: 8px !important;
    }
    .lk-button-group-menu {
      display: none !important;
    }
  }
}
